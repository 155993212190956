












import { Component, Prop, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'

@Component({})
export default class AppSwitch extends Mixins(StateMixin) {
  @Prop({ type: String, required: true })
  public label!: string

  @Prop({ type: [String, Number], default: '70px' })
  public labelWidth!: string | number
}
