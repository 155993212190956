










































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { defaultState } from '@/store/config/index'

@Component({
  components: {}
})
export default class TimelapseSettings extends Vue {
  @Prop({
    type: Boolean,
    required: true
  })
  value!: boolean

  get isVariableFps () {
    return this.$store.state.timelapse.variable_fps
  }

  set isVariableFps (value: boolean) {
    this.$store.dispatch('timelapse/settings', {
      variable_fps: value
    })
  }

  get isPreviewImage () {
    return this.$store.state.timelapse.previewimage
  }

  set isPreviewImage (value: boolean) {
    this.$store.dispatch('timelapse/settings', {
      previewimage: value
    })
  }

  setSetting (value: object, name: string) {
    const params: any = {}
    params[name] = value
    this.$store.dispatch('timelapse/settings', params)
  }
}
