










































































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import StatesMixin from '@/mixins/state'
import FileSystemMenu from './FileSystemMenu.vue'
import FileSystemFilterMenu from './FileSystemFilterMenu.vue'
import { AppTableHeader } from '@/types'

@Component({
  components: {
    FileSystemMenu,
    FileSystemFilterMenu
  }
})
export default class FileSystemToolbar extends Mixins(StatesMixin) {
  // The currently active root.
  @Prop({ type: String, required: true })
  root!: string

  @Prop({ type: String, required: false })
  name!: string;

  // Can be a list of roots, or a single root.
  @Prop({ type: Array, required: false })
  roots!: string[];

  // Currently defined list of headers.
  @Prop({ type: Array, required: false })
  headers!: AppTableHeader[];

  // The current path
  @Prop({ type: String, required: false })
  path!: string;

  // If the controls are disabled or not.
  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  // If the fs is loading or not.
  @Prop({ type: Boolean, default: false })
  loading!: boolean

  @Prop({ type: String, default: '' })
  search!: string

  textSearch = ''

  get readonly () {
    return this.$store.getters['files/getRootProperties'](this.root).readonly
  }

  get lowOnSpace () {
    if (!this.klippyReady) return false
    return this.$store.getters['files/getLowOnSpace']
  }

  get supportsHistoryComponent () {
    return this.$store.getters['server/componentSupport']('history')
  }

  // Properties of the current root.
  get rootProperties () {
    return this.$store.getters['files/getRootProperties'](this.root)
  }

  // Only show roots that have been registered.
  get registeredRoots () {
    return this.roots.filter(r => this.$store.state.server.info.registered_directories.includes(r))
  }

  mounted () {
    this.textSearch = this.search
  }

  handleUpload (files: FileList | File[], print: boolean) {
    this.$emit('upload', files, print)
  }
}
