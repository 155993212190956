





























import { Component, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import Console from '@/components/widgets/console/Console.vue'

@Component({
  components: {
    Console
  }
})
export default class UpdatingDialog extends Mixins(StateMixin) {
  invokedDialog = false
  get showDialog () {
    if (
      this.invokedDialog === true ||
      this.$store.state.version.busy
    ) {
      this.invokedDialog = true
      return true
    }
    return false
  }

  get updating () {
    return this.$store.state.version.busy
  }

  get title () {
    if (this.updating) {
      return this.$t('app.version.status.updating')
    } else {
      return this.$t('app.version.status.finished')
    }
  }

  get buttonTitle () {
    if (this.updating) {
      return this.$t('app.version.status.updating')
    } else {
      return this.$t('app.version.btn.finish')
    }
  }

  get responses () {
    return this.$store.getters['version/getResponses']
  }

  close () {
    this.invokedDialog = false
    this.$store.commit('version/setClearUpdateResponse')
  }
}
