import { MutationTree } from 'vuex'
import { TimelapseState } from '@/store/timelapse/types'
import { defaultState } from './index'

export const mutations: MutationTree<TimelapseState> = {
  setReset (state) {
    Object.assign(state, defaultState())
  },

  setSettings (state, payload: TimelapseState) {
    if (payload) Object.assign(state, payload)
    console.log('state', state)
  }
}
