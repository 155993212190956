
















































import { Component, Mixins, Prop } from 'vue-property-decorator'
import StatesMixin from '@/mixins/state'
import FileSystemMenu from './FileSystemMenu.vue'
import FileSystemFilterMenu from './FileSystemFilterMenu.vue'

@Component({
  components: {
    FileSystemMenu,
    FileSystemFilterMenu
  }
})
export default class FileSystemBulkActions extends Mixins(StatesMixin) {
  // The current path
  @Prop({ type: String, required: false })
  path!: string;
}
