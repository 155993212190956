import { MutationTree } from 'vuex'
import { KeyState, RemoteState } from '@/store/remote/types'
import { defaultState } from './index'

export const mutations: MutationTree<RemoteState> = {
  setReset (state) {
    Object.assign(state, defaultState())
  },

  setStatus (state, payload: any) {
    state.status = payload.result
  },

  setKey (state, playload: KeyState) {
    state.pkey = playload
  },

  setAnswer (state, playload: RTCSessionDescription) {
    state.answer = playload
  },

  emptyKey (state, playload: KeyState) {
    state.pkey = {
      text: '',
      message: ''
    }
  }
}
