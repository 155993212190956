
























































import { Component, Mixins, Prop } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import ToolheadMixin from '@/mixins/toolhead'

@Component({})
export default class SaveMeshDialog extends Mixins(StateMixin, ToolheadMixin) {
  @Prop({ type: Boolean, default: false })
  value!: string;

  @Prop({ type: String })
  existingName!: string;

  mounted () {
    this.name = 'default'
    this.removeDefault = false
  }

  valid = true
  name = 'default'
  removeDefault = false

  rules = [
    (v: string) => !!v || this.$t('app.general.simple_form.error.required')
  ]

  handleSubmit () {
    if (this.valid) {
      this.$emit('save', { name: this.name, removeDefault: this.removeDefault })
      this.$emit('input', false)
    }
  }
}
