import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { RemoteState } from '@/store/remote/types'
import { SocketActions } from '@/api/socketActions'
import consola from 'consola'
import { Waits } from '@/globals'

export const actions: ActionTree<RemoteState, RootState> = {
  async reset ({ commit }) {
    commit('setReset')
  },

  async init () {
    // SocketActions.machineDevicePowerDevices()
    SocketActions.machineTimelapseSettings()
    consola.info('init timelapse')
  },

  async onStatus ({ commit, dispatch }, payload) {
    if (payload) {
      console.debug('remote_status', payload)
      commit('setStatus', payload)
      dispatch('wait/removeWait', Waits.onRemoteStatus, { root: true })
    }
  },

  async onKeygen ({ commit, dispatch }, payload) {
    if (payload) {
      commit('setKey', payload)
      dispatch('wait/removeWait', Waits.onRemoteGenKey, { root: true })
    }
  },

  async onAnswer ({ commit }, payload) {
    console.info('onAnswer', payload)
    if (payload) {
      commit('setAnswer', payload)
    }
  }
}
