import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { RootState } from '../types'
import { RemoteState } from '@/store/remote/types'

/**
 * Maintains the state of the console
 */
export const defaultState = (): RemoteState => {
  return {
    status: 'stop',
    pkey: null,
    answer: null
  }
}

export const state = defaultState()

const namespaced = true

export const remote: Module<RemoteState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
