




























































import { Component, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import { IroColor } from '@irojs/iro-core'
import { SupportedTheme, ThemeConfig } from '@/store/config/types'
import ThemePicker from '../ui/AppColorPicker.vue'

@Component({
  components: {
    ThemePicker
  }
})
export default class ThemeSettings extends Mixins(StateMixin) {
  preset: SupportedTheme | null = null

  get themePreset () {
    return this.$store.getters['config/getCurrentThemePreset']
  }

  set themePreset (d: SupportedTheme) {
    const value: ThemeConfig = {
      isDark: d.isDark,
      logo: d.logo,
      currentTheme: {
        primary: d.color
      }
    }

    this.setTheme(d.color, d.isDark)
    this.$store.dispatch('config/saveByPath', {
      path: 'uiSettings.theme',
      value,
      server: true
    })
  }

  get theme () {
    return this.$store.getters['config/getTheme']
  }

  get themeColor () {
    return this.theme.currentTheme.primary
  }

  handleChangeThemeColor (value: { channel: string; color: IroColor }) {
    this.setTheme(value.color.hexString, this.isDark)
    this.$store.dispatch('config/saveByPath', {
      path: 'uiSettings.theme.currentTheme.primary',
      value: value.color.hexString,
      server: true
    })
  }

  get isDark () {
    return this.theme.isDark
  }

  set isDark (value: boolean) {
    this.setTheme(this.themeColor, value)
    this.$store.dispatch('config/saveByPath', {
      path: 'uiSettings.theme.isDark',
      value,
      server: true
    })
  }

  get themePresets () {
    return this.$store.state.config.hostConfig.themePresets
  }

  setTheme (primary: string, isDark: boolean) {
    this.$vuetify.theme.dark = isDark
    this.$vuetify.theme.currentTheme.primary = primary
  }

  handleReset () {
    const d = this.$store.getters['config/getCurrentThemePreset']
    const value: ThemeConfig = {
      isDark: d.isDark,
      logo: d.logo,
      currentTheme: {
        primary: d.color
      }
    }
    this.setTheme(d.color, true)
    this.$store.dispatch('config/saveByPath', {
      path: 'uiSettings.theme',
      value,
      server: true
    })
  }
}
