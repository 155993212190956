




















































































import { Component, Prop, Watch, Mixins, Ref } from 'vue-property-decorator'
import VSlider from 'vuetify/lib/components/VSlider.vue'
import StateMixin from '@/mixins/state'
import { VForm } from '@/types/vuetify'

@Component({})
export default class AppSlider extends Mixins(StateMixin) {
  @Prop({ type: Number, required: true })
  public value!: number

  @Prop({ type: Number, required: false })
  public resetValue!: number;

  @Prop({ type: String, required: true })
  public label!: string

  @Prop({ type: Array, default: () => { return [] } })
  public rules!: []

  @Prop({ type: Boolean, default: false })
  public disabled!: boolean

  @Prop({ type: Boolean, default: false })
  public locked!: boolean

  @Prop({ type: Boolean, default: false })
  public loading!: boolean

  @Prop({ type: Number, default: 0 })
  public min!: number;

  @Prop({ type: Number, default: 100 })
  public max!: number;

  @Prop({ type: Boolean, default: false })
  public overridable!: boolean;

  @Prop({ type: Number, default: 1 })
  public step!: number;

  @Prop({ type: String })
  public suffix!: string;

  @Ref('slider')
  slider!: VSlider;

  @Ref('form')
  form!: VForm;

  valid = true
  lockState = false
  overridden = false
  internalValue = this.value
  internalMax = this.max
  pending = false

  // If the parent updates the value.
  @Watch('value')
  onValue (value: string | number) {
    value = +value
    if (value !== this.internalValue) {
      this.internalValue = +value
    }
    this.pending = false
  }

  // If one of our controls updates the value.
  @Watch('internalValue')
  onInternalValue (value: string | number) {
    if (this.valid) {
      if (
        value > this.max &&
        this.overridable
      ) {
        // This is overridable, and the user wants to increase
        // past the given max. So, disable the slider - and let it be.
        this.overridden = true
        this.internalMax = +value
      } else {
        // This is not overridable, or the user has reverted back to a value
        // within the given max. So, re-enable the slider - and let it be.
        this.overridden = false
        this.internalMax = this.max
      }
      this.$emit('input', +value)
    }
  }

  get isLocked () {
    return this.lockState
  }

  set isLocked (val: boolean) {
    this.lockState = val
    this.$emit('update:locked', val)
  }

  @Watch('locked')
  onLockedChange (val: boolean) {
    this.lockState = val
  }

  get isMobile () {
    return this.$vuetify.breakpoint.mobile
  }

  get textRules () {
    // Apply a min and max rule as per the slider.
    const rules = [
      ...this.rules,
      (v: string) => +v >= this.min || this.$t('app.general.simple_form.error.min', { min: this.min })
    ]
    if (!this.overridable) {
      rules.push(
        (v: string) => +v <= this.max || this.$t('app.general.simple_form.error.max', { max: this.max })
      )
    }
    return rules
  }

  mounted () {
    this.lockState = this.locked
  }

  handleChange (value: string | number) {
    value = +value
    if (
      value !== this.value &&
      !this.pending
    ) {
      if (this.valid) {
        this.pending = true
        this.$emit('change', value)
      } else {
        this.internalValue = this.value
      }
      this.lockState = this.locked
    }
  }

  handleReset () {
    this.internalValue = this.resetValue
    this.lockState = this.locked
    this.$emit('change', this.resetValue)
  }
}
