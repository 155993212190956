












import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({ })
export default class FileViewDialog extends Vue {
  @Prop({
    type: Boolean,
    required: true
  })
  value!: boolean

  @Prop({
    type: String,
    required: true
  })
  public url!: string

  get cameraOptions () {
    return {
      // video js and plugin options
      width: 480,
      autoplay: true,
      sources: [{
        type: 'video/mp4',
        src: this.url
      }]
    }
  }
}
