







































































































import { Component, Vue } from 'vue-property-decorator'
import { defaultState } from '@/store/config'
import TimelapseTakeFrameDialog from '@/components/settings/timelapse/TimelapseTakeFrameDialog.vue'
import TimelapseRenderDialog from '@/components/settings/timelapse/TimelapseRenderDialog.vue'

@Component({
  components: {
    TimelapseRenderDialog,
    TimelapseTakeFrameDialog
  }
})
export default class TimelapseSettings extends Vue {
  rules = {
    numRequired: (v: number | string) => v !== '' || 'Required',
    numMin: (v: number) => v > 0 || 'Must be greater than 0'
  }

  enabled = true

  takeFrameDialog = {
    open: false
  }

  renderDialog = {
    open: false
  }

  get isEnabled () {
    return this.$store.state.timelapse.enabled
  }

  set isEnabled (value: boolean) {
    this.$store.dispatch('timelapse/settings', {
      enabled: value
    })
  }

  get isAutoRender () {
    return this.$store.state.timelapse.autorender
  }

  set isAutoRender (value: boolean) {
    this.$store.dispatch('timelapse/settings', {
      autorender: value
    })
  }

  get isGcodeVerbose () {
    return this.$store.state.timelapse.gcode_verbose
  }

  set isGcodeVerbose (value: boolean) {
    this.$store.dispatch('timelapse/settings', {
      gcode_verbose: value
    })
  }

  get isParkhead () {
    return this.$store.state.timelapse.parkhead
  }

  set isParkhead (value: boolean) {
    this.$store.dispatch('timelapse/settings', {
      parkhead: value
    })
  }

  get isVariableFps () {
    return this.$store.state.timelapse.variable_fps
  }

  set isVariableFps (value: boolean) {
    this.$store.dispatch('timelapse/settings', {
      variable_fps: value
    })
  }

  get isPreviewImage () {
    return this.$store.state.timelapse.previewimage
  }

  set isPreviewImage (value: boolean) {
    this.$store.dispatch('timelapse/settings', {
      previewimage: value
    })
  }

  get isSaveframes () {
    return this.$store.state.timelapse.saveframes
  }

  set isSaveframes (value: boolean) {
    this.$store.dispatch('timelapse/settings', {
      saveframes: value
    })
  }

  setSetting (value: object, name: string) {
    const params: any = {}
    params[name] = value
    this.$store.dispatch('timelapse/settings', params)
  }

  handleTakeFrameDialog () {
    //
    this.takeFrameDialog = {
      open: true
    }
  }

  handleRenderDialog () {
    //
    this.renderDialog = {
      open: true
    }
  }
}
