


































































import { FileFilter } from '@/store/files/types'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class FileSystemFilterMenu extends Vue {
  @Prop({ type: String, required: true })
  root!: string

  // If the controls are disabled or not.
  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  model = []
  filters: FileFilter[] = [
    {
      value: 'print_start_time',
      text: this.$tc('app.file_system.filters.label.print_start_time'),
      desc: this.$tc('app.file_system.filters.label.print_start_time_desc')
    }
  ]

  get readonly () {
    return this.$store.getters['files/getRootProperties'](this.root).readonly
  }

  @Watch('model')
  onFiltersChange (model: any) {
    this.$emit('change', model)
  }
}
