











































import { Component, Mixins, Prop } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'

@Component({})
export default class FileNameDialog extends Mixins(StateMixin) {
  @Prop({ type: Boolean, required: true })
  value!: boolean

  @Prop({ type: String, required: true })
  title!: string

  @Prop({ type: String, required: true })
  label!: string

  @Prop({ type: Array, required: false })
  rules!: []

  @Prop({ type: String, required: true })
  name!: string

  newName = ''
  valid = false

  mounted () {
    this.newName = this.name
  }

  handleSave () {
    if (this.valid) {
      this.$emit('save', this.newName)
      this.$emit('input', false)
    }
  }
}
