import { Module } from 'vuex'
import { TimelapseState } from '@/store/timelapse/types'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { RootState } from '../types'

/**
 * Maintains the state of the console
 */
export const defaultState = (): TimelapseState => {
  return {
    autorender: true,
    blockedsettings: [],
    camera: '',
    constant_rate_factor: 23,
    duplicatelastframe: 0,
    enabled: false,
    extraoutputparams: '',
    gcode_verbose: true,
    hyperlapse_cycle: 30,
    mode: 'layermacro',
    output_framerate: 30,
    park_custom_pos_dz: 0,
    park_custom_pos_x: 0,
    park_custom_pos_y: 0,
    park_extrude_distance: 1,
    park_extrude_speed: 15,
    park_retract_distance: 1,
    park_retract_speed: 15,
    park_travel_speed: 100,
    parkhead: false,
    parkpos: 'back_left',
    pixelformat: 'yuv420p',
    previewimage: true,
    rotation: 0,
    saveframes: false,
    snapshoturl: 'http://localhost:8080/?action=snapshot',
    targetlength: 10,
    time_format_code: '%Y%m%d_%H%M',
    variable_fps: false,
    variable_fps_max: 60,
    variable_fps_min: 5
  }
}

export const state = defaultState()

const namespaced = true

export const timelapse: Module<TimelapseState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
