






















































import { Component, Vue } from 'vue-property-decorator'
import { startCase, capitalize } from 'lodash'

@Component({})
export default class AppNotificationMenu extends Vue {
  get user () {
    return this.$store.getters['auth/getCurrentUser']
  }

  get currentUser () {
    if (!this.user) return ''
    if (
      this.user.username === '_TRUSTED_USER_' ||
      this.user.username === '_API__API_KEY_USER_USER_'
    ) {
      return capitalize(startCase(this.user.username))
    } else {
      return this.user.username
    }
  }

  get isTrustedOnly () {
    if (!this.user) return false
    return (
      this.user.username === '_TRUSTED_USER_' ||
      this.user.username === '_API__API_KEY_USER_USER_'
    )
  }

  async handleLogout () {
    await this.$store.dispatch('auth/checkTrust')
  }
}
