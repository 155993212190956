import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { TimelapseState } from '@/store/timelapse/types'
import { SocketActions } from '@/api/socketActions'
import consola from 'consola'

export const actions: ActionTree<TimelapseState, RootState> = {
  async reset ({ commit }) {
    commit('setReset')
  },

  async init () {
    // SocketActions.machineDevicePowerDevices()
    SocketActions.machineTimelapseSettings()
    consola.info('init timelapse')
  },

  async settings ({ commit, state }, payload) {
    consola.debug('timelapse setting', payload, commit, state)
    SocketActions.machineTimelapseSettings(payload)
  },

  // 调用timelapse设置接口结束的回调函数
  async onSettings ({ commit }, payload) {
    if (payload) {
      console.debug('timelapse_settings', payload)
      commit('setSettings', payload)
    }
  }
}
