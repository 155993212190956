














































import { Component, Mixins, Prop } from 'vue-property-decorator'

import StateMixin from '@/mixins/state'

@Component({})
export default class AppNavItem extends Mixins(StateMixin) {
  @Prop({ type: String })
  title!: string

  @Prop({ type: String })
  to!: string

  @Prop({ type: Boolean, default: false })
  exact!: boolean

  @Prop({ type: String })
  icon!: string

  get isMobile () {
    return this.$vuetify.breakpoint.mobile
  }
}

