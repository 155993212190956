
















































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { defaultState } from '@/store/config/index'

@Component({
  components: {}
})
export default class TimelapseTakeFrameDialog extends Vue {
  @Prop({
    type: Boolean,
    required: true
  })
  value!: boolean

  get isGcodeVerbose () {
    return this.$store.state.timelapse.gcode_verbose
  }

  set isGcodeVerbose (value: boolean) {
    this.$store.dispatch('timelapse/settings', {
      gcode_verbose: value
    })
  }

  get isParkhead () {
    return this.$store.state.timelapse.parkhead
  }

  set isParkhead (value: boolean) {
    this.$store.dispatch('timelapse/settings', {
      parkhead: value
    })
  }

  setSetting (value: object, name: string) {
    const params: any = {}
    params[name] = value
    this.$store.dispatch('timelapse/settings', params)
  }
}
