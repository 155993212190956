
















































import { Component, Mixins, Prop } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'

@Component({})
export default class AppNamedInput extends Mixins(StateMixin) {
  @Prop({ type: [String, Number], required: true })
  public value!: string | number;

  @Prop({ type: String, required: true })
  public label!: string

  @Prop({ type: [String, Number], required: false })
  public resetValue!: string | number;

  @Prop({ type: Boolean, default: false })
  public readonly!: boolean

  @Prop({ type: Boolean, default: false })
  public disabled!: boolean

  @Prop({ type: Boolean, default: false })
  public loading!: boolean

  @Prop({ type: String })
  public suffix!: string;

  @Prop({ type: Array, default: () => { return [] } })
  public rules!: []

  handleChange (value: string | number) {
    if (this.value !== value) this.$emit('change', value)
  }

  handleReset () {
    this.$emit('input', this.resetValue)
    if (this.value !== this.resetValue) this.$emit('change', this.resetValue)
  }
}
