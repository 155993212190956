

























































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import { FilesUpload } from '@/store/files/types'

@Component({})
export default class FileSystemUploadDialog extends Mixins(StateMixin) {
  @Prop({ type: Boolean, default: false })
  value!: boolean;

  @Prop({ type: Array })
  files!: FilesUpload[]
}
