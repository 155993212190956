
































































import { Component, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import { SocketActions } from '@/api/socketActions'

@Component({
  components: {}
})
export default class RemoteSettings extends Mixins(StateMixin) {
  mounted () {
    SocketActions.remoteStatus()
  }

  get isConnect () {
    if (this.$store.state.remote.status === 'running') {
      return true
    }
    return false
  }

  set isConnect (value: boolean) {
    if (value) {
      SocketActions.remoteStatus('start')
    } else {
      SocketActions.remoteStatus('stop')
    }
  }

  get supportsRemoteManager () {
    return this.$store.getters['server/componentSupport']('remote_manager')
  }

  get Key () {
    return this.$store.state.remote.pkey
  }

  get isShowKeyDialog () {
    return !!this.$store.state.remote.pkey.text
  }

  /* set isShowKeyDialog () {
    this.$store.commit('remote/emptyKey')
  } */

  handleGenKey () {
    this.$confirm(
      '注意：重设密钥后，原密钥将失效，请及时更新密钥',
      {
        title: '确认',
        color: 'card-heading',
        icon: '$error'
      }
    )
      .then(res => {
        if (res) {
          // this.$emit('click')
          // this.hostReboot()
          SocketActions.remoteKeygen('new')
        }
      })
  }

  handleEmptyKey () {
    this.$store.commit('remote/emptyKey')
  }
}
