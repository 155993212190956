
















































import { Component, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'

@Component({})
export default class CamerasMenu extends Mixins(StateMixin) {
  get activeCamera () {
    let id = this.$store.getters['cameras/getActiveCamera']
    const camera = this.$store.getters['cameras/getCameraById'](id)

    // If no cam was found, the active id probably no longer exists - so
    // we'll set it to all.
    if (!camera) id = 'all'

    return (id === 'all')
      ? this.$t('app.general.btn.all')
      : camera.name
  }

  get cameras () {
    return this.$store.getters['cameras/getEnabledCameras']
  }
}
